var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pivot-table"
  }, [_c('hot-table', {
    ref: "hotTableComponent",
    attrs: {
      "data": _vm.data,
      "settings": _vm.hotSettings
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }