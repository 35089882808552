const data = [
    [
        true,
        "Zoomzone",
        "03/05/2020",
        "03/05/2020",
        "88-2768633",
        35750.50,
        4,
    ],
    [
        true,
        "Meeveo",
        "27/03/2020",
        "27/03/2020",
        "51-6775945",
        35750.50,
        1,
    ],
    [
        false,
        "Buzzdog",
        "29/08/2020",
        "29/08/2020",
        "44-4028109",
        35750.50,
        2,
    ],
    [
        true,
        "Katz",
        "02/10/2020",
        "02/10/2020",
        "08-2758492",
        35750.50,
        1,
    ],
    [
        false,
        "Jaxbean",
        "28/09/2020",
        "28/09/2020",
        "84-3557705",
        35750.50,
        6,
    ],
    [
        false,
        "Wikido",
        "24/06/2020",
        "24/06/2020",
        "20-9397637",
        35750.50,
        1,
    ],
    [
        false,
        "Browsedrive",
        "13/03/2020",
        "13/03/2020",
        "36-0079556",
        35750.50,
        1,
    ],
    [
        false,
        "Twinder",
        "06/04/2020",
        "06/04/2020",
        "41-1489542",
        35750.50,
        1,
    ],
    [
        false,
        "Jetwire",
        "01/02/2020",
        "01/02/2020",
        "37-1531629",
        35750.50,
        1,
    ],
    [
        false,
        "Chatterpoint",
        "14/07/2020",
        "14/07/2020",
        "25-5083429",
        35750.50,
        1,
    ],
    [
        false,
        "Twinder",
        "31/08/2020",
        "31/08/2020",
        "04-4281278",
        35750.50,
        1,
    ],
    [
        false,
        "Midel",
        "27/06/2020",
        "27/06/2020",
        "55-1711908",
        35750.50,
        1,
    ],
    [
        false,
        "Yodo",
        "16/03/2020",
        "16/03/2020",
        "58-8360815",
        35750.50,
        1,
    ],
    [
        false,
        "Camido",
        "13/09/2020",
        "13/09/2020",
        "10-3786104",
        35750.50,
        1,
    ],
    [
        false,
        "Eire",
        "10/04/2020",
        "10/04/2020",
        "45-1186054",
        35750.50,
        1,
    ],
    [
        false,
        "Vinte",
        "22/01/2020",
        "22/01/2020",
        "62-6202742",
        35750.50,
        1,
    ],
    [
        false,
        "Twitterlist",
        "09/11/2020",
        "09/11/2020",
        "88-9646223",
        35750.50,
        1,
    ],
    [
        false,
        "Eidel",
        "19/09/2020",
        "19/09/2020",
        "45-5588112",
        35750.50,
        1,
    ],
    [
        false,
        "Trunyx",
        "09/03/2020",
        "09/03/2020",
        "66-6271819",
        35750.50,
        1,
    ],
    [
        false,
        "Katz",
        "05/03/2020",
        "05/03/2020",
        "38-9245023",
        35750.50,
        1,
    ],
    [
        false,
        "Kaymbo",
        "24/12/2020",
        "24/12/2020",
        "44-5916927",
        35750.50,
        1,
    ],
    [
        false,
        "Ozu",
        "13/06/2020",
        "13/06/2020",
        "31-5449914",
        35750.50,
        1,
    ],
    [
        false,
        "Rhynyx",
        "05/12/2020",
        "05/12/2020",
        "19-9413869",
        35750.50,
        1,
    ],
    [
        false,
        "Flashset",
        "14/12/2020",
        "14/12/2020",
        "25-9807605",
        35750.50,
        1,
    ],
    [
        false,
        "Yata",
        "12/06/2020",
        "12/06/2020",
        "74-4291983",
        35750.50,
        1,
    ],
    [
        false,
        "Brainlounge",
        "10/03/2020",
        "10/03/2020",
        "83-0980643",
        35750.50,
        1,
    ],
    [
        false,
        "Babblestorm",
        "10/10/2020",
        "10/10/2020",
        "19-2878430",
        35750.50,
        1,
    ],
    [
        false,
        "Youspan",
        "16/12/2020",
        "16/12/2020",
        "19-1838230",
        35750.50,
        1,
    ],
    [
        false,
        "Nlounge",
        "31/10/2020",
        "31/10/2020",
        "32-2267938",
        35750.50,
        1,
    ],
    [
        false,
        "Twinte",
        "17/08/2020",
        "17/08/2020",
        "79-2821972",
        35750.50,
        1,
    ],
    [
        false,
        "Oyonder",
        "04/12/2020",
        "04/12/2020",
        "46-6597557",
        35750.50,
        1,
    ],
    [
        false,
        "Gigabox",
        "03/02/2020",
        "03/02/2020",
        "15-1793960",
        35750.50,
        1,
    ],
    [
        false,
        "Livetube",
        "16/05/2020",
        "16/05/2020",
        "86-0811003",
        35750.50,
        1,
    ],
    [
        false,
        "Voomm",
        "15/07/2020",
        "15/07/2020",
        "95-3068680",
        35750.50,
        1,
    ],
    [
        false,
        "Voonyx",
        "27/11/2020",
        "27/11/2020",
        "35-3085360",
        35750.50,
        1,
    ],
    [
        false,
        "Zoombeat",
        "03/11/2020",
        "03/11/2020",
        "56-8673088",
        35750.50,
        1,
    ],
    [
        false,
        "Roomm",
        "16/03/2020",
        "16/03/2020",
        "76-0085918",
        35750.50,
        1,
    ],
    [
        false,
        "Leenti",
        "16/05/2020",
        "16/05/2020",
        "03-0893276",
        35750.50,
        1,
    ],
    [
        false,
        "Jetpulse",
        "08/02/2020",
        "08/02/2020",
        "79-9013306",
        35750.50,
        1,
    ],
    [
        false,
        "Katz",
        "15/02/2020",
        "15/02/2020",
        "55-7799920",
        35750.50,
        1,
    ],
    [
        false,
        "Cogidoo",
        "04/06/2020",
        "04/06/2020",
        "07-0881122",
        35750.50,
        1,
    ],
    [
        false,
        "Divavu",
        "24/02/2020",
        "24/02/2020",
        "58-6157387",
        35750.50,
        1,
    ],
    [
        false,
        "Mydeo",
        "07/12/2020",
        "07/12/2020",
        "12-2810010",
        35750.50,
        1,
    ],
    [
        false,
        "Browsebug",
        "14/01/2020",
        "14/01/2020",
        "64-9249984",
        35750.50,
        1,
    ],
    [
        false,
        "Layo",
        "24/04/2020",
        "24/04/2020",
        "45-0739652",
        35750.50,
        1,
    ],
    [
        false,
        "Snaptags",
        "06/08/2020",
        "06/08/2020",
        "09-5712761",
        35750.50,
        1,
    ],
    [
        false,
        "Cogilith",
        "31/05/2020",
        "31/05/2020",
        "01-7345008",
        35750.50,
        1,
    ],
    [
        false,
        "Reallinks",
        "14/05/2020",
        "14/05/2020",
        "62-1065350",
        35750.50,
        1,
    ],
    [
        false,
        "Quaxo",
        "23/03/2020",
        "23/03/2020",
        "44-7241323",
        35750.50,
        1,
    ],
    [
        false,
        "Devify",
        "12/12/2020",
        "12/12/2020",
        "52-0295699",
        35750.50,
        1,
    ],
    [
        false,
        "Youopia",
        "01/04/2020",
        "01/04/2020",
        "52-2650922",
        35750.50,
        1,
    ],
    [
        false,
        "Ainyx",
        "27/02/2020",
        "27/02/2020",
        "48-3618525",
        35750.50,
        1,
    ],
    [
        false,
        "Browsetype",
        "28/04/2020",
        "28/04/2020",
        "51-8893923",
        35750.50,
        1,
    ],
    [
        false,
        "Muxo",
        "22/08/2020",
        "22/08/2020",
        "68-5911361",
        35750.50,
        1,
    ],
    [
        false,
        "Bubbletube",
        "04/10/2020",
        "04/10/2020",
        "41-5880042",
        35750.50,
        1,
    ],
    [
        false,
        "Fadeo",
        "23/04/2020",
        "23/04/2020",
        "90-5913983",
        35750.50,
        1,
    ],
    [
        false,
        "Yadel",
        "18/04/2020",
        "18/04/2020",
        "92-0960699",
        35750.50,
        1,
    ],
    [
        false,
        "Blognation",
        "10/01/2020",
        "10/01/2020",
        "06-9493898",
        35750.50,
        1,
    ],
    [
        false,
        "Devpoint",
        "25/12/2020",
        "25/12/2020",
        "69-5878565",
        35750.50,
        1,
    ],
    [
        false,
        "Aibox",
        "18/03/2020",
        "18/03/2020",
        "13-1133017",
        35750.50,
        1,
    ],
    [
        false,
        "Brightdog",
        "11/09/2020",
        "11/09/2020",
        "39-6530433",
        35750.50,
        1,
    ],
    [
        false,
        "Gabcube",
        "22/04/2020",
        "22/04/2020",
        "96-6860388",
        35750.50,
        1,
    ],
    [
        false,
        "Muxo",
        "05/06/2020",
        "05/06/2020",
        "30-0356137",
        35750.50,
        1,
    ],
    [
        false,
        "Tambee",
        "22/02/2020",
        "22/02/2020",
        "93-9058255",
        35750.50,
        1,
    ],
    [
        false,
        "Cogilith",
        "06/04/2020",
        "06/04/2020",
        "82-9268909",
        35750.50,
        1,
    ],
    [
        false,
        "Dynabox",
        "10/01/2020",
        "10/01/2020",
        "20-6913815",
        35750.50,
        1,
    ],
    [
        false,
        "Shuffledrive",
        "16/09/2020",
        "16/09/2020",
        "08-8238817",
        35750.50,
        1,
    ],
    [
        false,
        "Fivechat",
        "26/08/2020",
        "26/08/2020",
        "44-7370350",
        35750.50,
        1,
    ],
    [
        false,
        "Meembee",
        "27/12/2020",
        "27/12/2020",
        "01-3525949",
        35750.50,
        1,
    ],
    [
        false,
        "Dynazzy",
        "15/12/2020",
        "15/12/2020",
        "04-2414623",
        35750.50,
        1,
    ],
    [
        false,
        "Eare",
        "04/04/2020",
        "04/04/2020",
        "15-1917509",
        35750.50,
        1,
    ],
    [
        false,
        "Yozio",
        "15/03/2020",
        "15/03/2020",
        "06-2526845",
        35750.50,
        1,
    ],
    [
        false,
        "Quinu",
        "22/02/2020",
        "22/02/2020",
        "83-1713558",
        35750.50,
        1,
    ],
    [
        false,
        "Jazzy",
        "07/08/2020",
        "07/08/2020",
        "00-8892524",
        35750.50,
        1,
    ],
    [
        false,
        "Thoughtsphere",
        "28/11/2020",
        "28/11/2020",
        "39-5538991",
        35750.50,
        1,
    ],
    [
        false,
        "Leenti",
        "29/12/2020",
        "29/12/2020",
        "06-9002973",
        35750.50,
        1,
    ],
    [
        false,
        "Quaxo",
        "06/09/2020",
        "06/09/2020",
        "73-6104901",
        35750.50,
        1,
    ],
    [
        false,
        "Tanoodle",
        "24/05/2020",
        "24/05/2020",
        "68-7384479",
        35750.50,
        1,
    ],
    [
        false,
        "Feednation",
        "21/11/2020",
        "21/11/2020",
        "26-7757763",
        35750.50,
        1,
    ],
    [
        false,
        "Kayveo",
        "21/06/2020",
        "21/06/2020",
        "07-4873562",
        35750.50,
        1,
    ],
    [
        false,
        "Meevee",
        "16/11/2020",
        "16/11/2020",
        "46-5819554",
        35750.50,
        1,
    ],
    [
        false,
        "Twitterworks",
        "19/04/2020",
        "19/04/2020",
        "01-2666826",
        35750.50,
        1,
    ],
    [
        false,
        "Wikizz",
        "08/03/2020",
        "08/03/2020",
        "54-7090503",
        35750.50,
        1,
    ],
    [
        false,
        "Yoveo",
        "14/10/2020",
        "14/10/2020",
        "78-7658520",
        35750.50,
        1,
    ],
    [
        false,
        "Yakidoo",
        "12/10/2020",
        "12/10/2020",
        "23-9926318",
        35750.50,
        1,
    ],
    [
        false,
        "Oyope",
        "20/09/2020",
        "20/09/2020",
        "20-0179517",
        35750.50,
        1,
    ],
    [
        false,
        "Skipstorm",
        "01/10/2020",
        "01/10/2020",
        "02-9543343",
        35750.50,
        1,
    ],
    [
        false,
        "Minyx",
        "07/07/2020",
        "07/07/2020",
        "98-3938169",
        35750.50,
        1,
    ],
    [
        false,
        "Miboo",
        "25/07/2020",
        "25/07/2020",
        "68-5197934",
        35750.50,
        1,
    ],
    [
        false,
        "Realfire",
        "27/08/2020",
        "27/08/2020",
        "39-8260460",
        35750.50,
        1,
    ],
    [
        false,
        "Shufflester",
        "08/06/2020",
        "08/06/2020",
        "45-9776170",
        35750.50,
        1,
    ],
    [
        false,
        "Ntag",
        "06/12/2020",
        "06/12/2020",
        "45-0858451",
        35750.50,
        1,
    ],
    [
        false,
        "Jabberbean",
        "26/04/2020",
        "26/04/2020",
        "15-4247305",
        35750.50,
        1,
    ],
    [
        false,
        "Thoughtblab",
        "21/05/2020",
        "21/05/2020",
        "15-8534931",
        35750.50,
        1,
    ],
    [
        false,
        "Jabbertype",
        "25/07/2020",
        "25/07/2020",
        "23-1251557",
        35750.50,
        1,
    ],
    [
        false,
        "Buzzshare",
        "07/07/2020",
        "07/07/2020",
        "86-5920601",
        35750.50,
        1,
    ],
    [
        false,
        "Roodel",
        "13/01/2020",
        "13/01/2020",
        "48-1055459",
        35750.50,
        1,
    ],
    [
        false,
        "Zoovu",
        "03/06/2020",
        "03/06/2020",
        "12-7842022",
        35750.50,
        1,
    ],
    [
        false,
        "Photofeed",
        "14/07/2020",
        "14/07/2020",
        "94-5088099",
        35750.50,
        1,
    ]
];

export const SELECTED_CLASS = "selected";
export const ODD_ROW_CLASS = "odd";

export function getData() {
    return data;
}
