import Handsontable from "handsontable";
import { SELECTED_CLASS, ODD_ROW_CLASS } from "./tableData";

const headerAlignments = new Map([
    ["9", "htCenter"],
    ["10", "htRight"],
    ["12", "htCenter"]
]);

type AddClassesToRows = (
    TD: HTMLTableCellElement,
    row: number,
    column: number,
    prop: number | string,
    value: any,
    cellProperties: Handsontable.CellProperties
) => void;

export const addClassesToRows: AddClassesToRows = (
    TD,
    row,
    column,
    prop,
    value,
    cellProperties
) => {
    // Adding classes to `TR` just while rendering first visible `TD` element
    if (column !== 0) {
        return;
    }

    const parentElement = TD.parentElement;

    if (parentElement === null) {
        return;
    }

    // Add class to selected rows
    if (cellProperties.instance.getDataAtRowProp(row, "0")) {
        Handsontable.dom.addClass(parentElement, SELECTED_CLASS);
    } else {
        Handsontable.dom.removeClass(parentElement, SELECTED_CLASS);
    }

    // Add class to odd TRs
    if (row % 2 === 0) {
        Handsontable.dom.addClass(parentElement, ODD_ROW_CLASS);
    } else {
        Handsontable.dom.removeClass(parentElement, ODD_ROW_CLASS);
    }
};

type DrawCheckboxInRowHeaders = (
    this: Handsontable,
    row: number,
    TH: HTMLTableCellElement
) => void;

export const drawCheckboxInRowHeaders: DrawCheckboxInRowHeaders = function drawCheckboxInRowHeaders(
    row,
    TH
) {
    const input = document.createElement("input");

    input.type = "checkbox";

    if (row >= 0 && this.getDataAtRowProp(row, "0")) {
        input.checked = true;
    }

    Handsontable.dom.empty(TH);

    TH.appendChild(input);
};

export function alignHeaders(
    this: Handsontable,
    column: number,
    TH: HTMLTableCellElement
) {
    if (column < 0) {
        return;
    }

    if (TH.firstChild) {
        const alignmentClass = this.isRtl() ? "htRight" : "htLeft";

        if (headerAlignments.has(column.toString())) {
            Handsontable.dom.removeClass(
                TH.firstChild as HTMLElement,
                alignmentClass
            );
            Handsontable.dom.addClass(
                TH.firstChild as HTMLElement,
                headerAlignments.get(column.toString()) as string
            );
        } else {
            Handsontable.dom.addClass(TH.firstChild as HTMLElement, alignmentClass);
        }
    }
}

type ChangeCheckboxCell = (
    this: Handsontable,
    event: MouseEvent,
    coords: { row: number; col: number }
) => void;

export const changeCheckboxCell: ChangeCheckboxCell = function changeCheckboxCell(
    event,
    coords
) {
    const target = event.target as HTMLInputElement;

    if (coords.col === -1 && event.target && target.nodeName === "INPUT") {
        event.preventDefault(); // Handsontable will render checked/unchecked checkbox by it own.

        this.setDataAtRowProp(coords.row, "0", !target.checked);
    }
};

type AddClassWhenNeeded = (
    td: HTMLTableCellElement,
    cellProperties: Handsontable.CellProperties
) => void;

const addClassWhenNeeded: AddClassWhenNeeded = (td, cellProperties) => {
    const className = cellProperties.className;

    if (className !== void 0) {
        Handsontable.dom.addClass(td, className);
    }
};

export const progressBarRenderer: any = (
    instance: any,
    td: any,
    row: any,
    column: any,
    prop: any,
    value: any,
    cellProperties: any
) => {
    const div = document.createElement("div");
    div.style.width = `${value * 10}px`;

    addClassWhenNeeded(td, cellProperties);
    Handsontable.dom.addClass(div, "progressBar");
    Handsontable.dom.empty(td);

    td.appendChild(div);
};

export const renderCurrency: any = (
    instance: any,
    td: any,
    row: any,
    column: any,
    prop: any,
    value: any,
    cellProperties: any
) => {
    let result = '';
    if (value < 1e3) return value;
    if (value >= 1e3) {
        let number: string | number = +(value / 1e3).toFixed(0);
        number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        result = number + " K"
    }

    const div = document.createElement("div");
    div.textContent = result;
    Handsontable.dom.empty(td);

    td.appendChild(div);
}
